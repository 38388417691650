import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CookieConsent from "react-cookie-consent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons"
import { faBullhorn, faSearch } from "@fortawesome/free-solid-svg-icons"
import OverlayMenu from "./overlay-menu"

const Layout = ({ description, logo, children }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
      <header>
        <section className="top-section">
          <OverlayMenu
            showMenu={showMenu}
            closeMenu={() => setShowMenu(false)}
          />
          <nav className="top-menu">
            <div className="left-links">
              <a
                className="links"
                href="https://www.facebook.com/uncaffeperdue/"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a
                className="links"
                href="https://www.instagram.com/uncaffeperdue/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a
                className="links"
                href="https://br.pinterest.com/uncaffeperdue/"
                target="_blank"
                rel="noreferrer"
                title="Pinterest"
              >
                <FontAwesomeIcon icon={faPinterest} size="lg" />
              </a>
              <Link className="links" to="/cafezinho" title="Newsletter">
                <FontAwesomeIcon icon={faBullhorn} size="lg" />
              </Link>
              <Link className="links" to="/pesquisa" title="Pesquisar">
                <FontAwesomeIcon icon={faSearch} size="lg" />
              </Link>
            </div>
            <div className="right-links">
              <button
                className="links"
                aria-label="Abrir menu"
                onClick={() => {
                  setShowMenu(true)
                }}
              >
                Menu
              </button>
            </div>
          </nav>
          <Link to="/">
            <GatsbyImage
              className="logo"
              image={logo}
              alt={"Logo do blog Un caffè per due"}
            />
          </Link>
          <span className="site-description">{description}</span>
        </section>
      </header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}. Un caffè per due. Feito com
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="Fechar e aceitar"
        style={{ fontFamily: "Mulish", fontWeight: 400 }}
        buttonStyle={{
          borderRadius: "1em",
          backgroundColor: "#f5e0a5",
          fontFamily: "Mulish",
          fontWeight: 600,
        }}
        cookieName="gatsby-gdpr-google-analytics"
        expires={150}
      >
        Este site utiliza cookies. Eles são necessários para melhorar sua
        experiência por aqui. Se você concorda com esta política, clique no
        botão respectivo ao lado. Para mais detalhes, acesse nossa página sobre{" "}
        {
          <Link className="dark-background" to={"/privacidade"}>
            privacidade
          </Link>
        }
        .
      </CookieConsent>
    </>
  )
}

export default Layout
